.viewBox {
    width: 768px;
    height: 100vh;
}

@media (max-width: 768px) {
    .viewBox {
        width: 100vw;
        height: 100vh;
    }
}
